<template>
  <div>
    <div id="echarts" style="height:400px;width:100%;"></div>
  </div>
</template>

<script>
export default {
  name: "BrandRegisterCategory",
  data() {
    return {}
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    company:{
      type: String,
    }

  },
  watch:{
    data(e){
      this.initEcharts()
    }
  },
  methods: {
    initEcharts() {
      console.log(this.company)
      let echarts = this.$echarts.init(document.getElementById('echarts'))
       var newdata = []
       for(var i = 0;i<this.data.length;i++){
          this.data[i].name =  this.data[i].key +'类'
         let res = JSON.parse(JSON.stringify(this.data[i]).replace(/doc_count/g, 'value').replace(/key/g, "name"));
         newdata.push(res)
       }
       if(newdata.length>8){
        let data1 = newdata.slice(0,7);
        let data2 = newdata.slice(7,newdata.length);
        let num = 0
        for(var i = 0;i<data2.length;i++){
          num += data2[1].value
        }
        let data3 = [{value: num, name: '其他'}]
        newdata = data1.concat(data3)
       }
      let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{b}:{c}个"
          },
          legend: {
            top: '90%',
            left: 'center'
          },
        series: [{
          type: 'pie',
          radius: ['40%', '50%'],
          center: ['50%', '40%'],
          color: ['#0E7CE2', '#FF8352', '#E271DE', '#F8456B', '#00FFFF', '#4AEAB0'],
          data:newdata,
          label: {
            normal: {
                formatter: '{b|{b}：}{d|{d}%}',
                rich: {
                    b: {
                        fontSize: 14,
                        color: '#333',
                        align: 'top',
                        // padding: 4
                    },
                    d: {
                        fontSize: 14,
                        color: '#333',
                        align: 'left',
                        // padding: 4
                    },
                }
            }
          }
        }]
        };
      echarts.setOption(option);
      var company = this.company;
      var onecate =  "";
      echarts.on('click', (params) =>{
       console.log(params)
       if(params.name != "其他"){
           this.$router.push({
            path:'/cate',
                  query:{
                    company:company,
                    onecate:params.name.substr(0,params.name.length-1)
                  }
          })
       }
      });

    }
  }
}
</script>

<style scoped>

</style>
