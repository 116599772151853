<template>
  <div v-loading="loading">
    <Else></Else>
    <div v-if="!isCompany" class="title">
      <div class="title-div1">商标管理系统<span>品牌保护再增一道防护墙</span></div>
      <div style="display: flex;justify-content: end;">
		  <div class="title-div2" style="margin-right: 20px;" @click="brandSet">品牌监控</div>
		  <div class="title-div2" @click="tocomfire" title="当您需要增加多个企业进行商标管理，请先申请完成关联企业认证">关联企业认证</div>
	  </div>
    </div>
    <div class="content-box">
      <div class="content-box-title">{{ isCompany ? companyName : '商标概览' }}</div>
      <el-row type="flex" justify="space-between">
        <el-col class="nav-line-main">
          <el-row type="flex">
            <img style="width:60px;height: 75px;" :src="require('/static/images/IPR-icon.png')">
            <div>
              <div class="sub-title">商标总数</div>
              <div class="sub-num" @click="goBrandList(1)">{{ data.trademarkAllNum || '0' }}</div>
            </div>
          </el-row>
        </el-col>
        <el-col class="nav-line">
          <div class="sub-title">已注册商标</div>
          <div class="sub-num" @click="goBrandList(2)">{{ data.regTrademarkALLNum || '0' }}</div>
        </el-col>
        <el-col class="nav-line">
          <div class="sub-title">申请中商标</div>
          <div class="sub-num" @click="goBrandList(3)">{{ data.applyTrademarkAllNum || '0' }}</div>
        </el-col>
        <el-col class="nav-line">
          <div class="sub-title">续展期商标</div>
          <div class="sub-num mb" @click="goBrandList(4)">{{ data.extensionTrademarkNum || '0' }}</div>
          <div class="sub-tip">(即将到期续费)</div>
        </el-col>
        <el-col class="nav-line">
          <div class="sub-title">宽展期商标</div>
          <div class="sub-num mb" @click="goBrandList(5)">{{ data.wideSpreadTrademarkNum || '0' }}</div>
          <div class="sub-tip">(过期半年续费)</div>
        </el-col>
        <el-col>
          <div class="sub-title">失效商标</div>
          <div class="sub-num" @click="goBrandList(6)">{{ data.loseEffcacyTrademarkNum || '0' }}</div>
        </el-col>
      </el-row>
    </div>
    <div class="content-box"  v-if="!isCompany">
      <div class="content-box-title">预警监控</div>
      <div class="mid">
        <span>公告期近似侵权</span>
        <span @click="gotomid">获取短信预警服务</span>
      </div>
      <div class="content-remid">
        <div class="content-remid-title">
          <div>企业名称</div>
          <div>侵权个数</div>
          <div>管理</div>
        </div>
        <div class="content-remid-detail" v-if="warnArr.length>0">
          <div v-for="(v,k) in warnArr" :key="k" v-show="v.doc_count!=0">
            <div>{{v.key}}</div>
            <div style="color:#FF8328">{{v.doc_count}}</div>
            <div style="color:#1167D5;cursor: pointer;" @click="gowarn(v.key,v.doc_count)">查看详情</div>
          </div>
        </div>
        <div class="nodata" v-show="warnArr.length==0">暂无数据</div>
      </div>
      <div class="content-tip">注：系统智能推断近似侵权商标共<span style="color:#FF8328">{{warnTotal}}</span>个，建议及时关注以保护您的商标权益</div>
    </div>
    <div class="content-box">
      <div class="content-box-title">
        {{
          !isCompany ? '申请进度' : '商标注册类别统计'
        }}
      </div>
      <BrandApplyProcess v-if="!isCompany&&allcompany.length!=0" :companyList="allcompany" :company="bj"></BrandApplyProcess>
      <BrandRegisterCategory v-if="isCompany" :data="data.count_intCls" :company="companyName"></BrandRegisterCategory>
    </div>
	<div class="content-box" v-if="!isCompany">
	  <div class="content-box-title">
	    品牌监控
	  </div>
	  <BrandMonitoring></BrandMonitoring>
<!-- 	  <div style="overflow: hidden;margin: 10px;">
		  <el-button style="color:#1167D5;float: right;" type="text" @click="detailHandle2">查看详情</el-button>
	  </div> -->
	  <div class="showall" @click="detailHandle2">查看详情</div>
	</div>
    <div class="content-box" v-if="!isCompany">
      <div class="content-box-title">
      统一管理
      </div>
      <el-row>
        <!-- <el-row type="flex" style="align-items: center;justify-content: flex-end;margin-bottom: 10px;">
          <el-input placeholder="请输入公司名称" size="mini" style="width:200px;margin-right:20px;" v-model.trim="searchCompanyName"></el-input>
          <el-button size="mini" class="main-btn" @click="init">搜索</el-button>
        </el-row> -->
        <el-table
          :data="tableData"
          :header-cell-style="{background:'#F8F8F8',color:'#333'}"
        >
          <el-table-column align="center" type="index" :index="indexMethod" label="排名"/>
          <el-table-column align="center" prop="key" label="公司名称"/>
          <el-table-column align="center" prop="doc_count" label="商标数量"/>
          <el-table-column align="center" label="管理">
            <template slot-scope="scope">
              <el-button style="color:#1167D5;" v-show="scope.row.doc_count !== 0" type="text" @click="detailHandle(scope.row.key)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
    <div class="content-box" v-if="isCompany">
      <div class="content-box-title">
        代理机构
      </div>
      <el-row>
        <el-table
          :data="data.count_brand_agent"
          :header-cell-style="{background:'#F8F8F8',color:'#333'}"
        >
          <el-table-column align="center" type="index" :index="indexMethod" label="排名"/>
          <el-table-column align="center" prop="key" label="公司名称"/>
          <el-table-column align="center" prop="doc_count" label="商标数量"/>
        </el-table>
      </el-row>
    </div>
  </div>
</template>

<script>


import {getAuthInfos, getIntClsCount, getUserKnowledgeMainTrademarkCount,getWarnlist,getfirst} from "../../api/api";
// import icon from '../../../static/images/IPR-icon.png'
import Else from '../ui/else.vue';
import BrandApplyProcess from "./components/BrandApplyProcess";
import BrandRegisterCategory from "./components/BrandRegisterCategory";
import BrandMonitoring from "./components/BrandMonitoring";
import './style.css'

export default {
  name: "intellectualProperty",
  components: {Else,BrandRegisterCategory,BrandApplyProcess,BrandMonitoring},
  data() {
    return {
      loading: false,
      // icon,
      tableData: [],
      tableData2: [],
      data: {},
      count_intCls: [],
      searchCompanyName: '',
      // 滨江企业
      bj:'',
      allcompany:[],
      // 侵权公司
      warnArr:[],
      warnTotal:0
    }
  },
  mounted() {
    this.init();
    this.getbj();
    this.init2();
    window.addEventListener('scroll',this.handleScroll)
  },
  computed: {
    companyName() {
      return this.$route.params.name
    },
    isCompany() {
      return !!this.$route.params.name
    }
  },
  methods: {
    async init() {
      this.loading = true;
      if (this.isCompany) {
        await this.getIntClsCount(this.companyName)
      } else {
        let comList = await this.getAuthInfos() || [];
        this.$store.commit('SET_AUTH_COMPANY', comList)
        await this.getUserKnowledgeMainTrademarkCount(comList)
      }
      //子向父发送数据
      this.$nextTick(()=>{
        var height =  document.documentElement.scrollHeight;
        console.log(height)
          window.parent.postMessage(
          {
            data: height,
          },
          "*"
        );
      })
      this.loading = false;
    },
    async getUserKnowledgeMainTrademarkCount(comList) {
      let res = await getUserKnowledgeMainTrademarkCount({comList})
      if (res.data.code === 200) {
        let list = res.data.data.count_tmapplicant || [];
        if(list && Array.isArray(list) && list.length > 0){
          list = list.filter(i=>i.key.indexOf(this.searchCompanyName) !== -1)
        }
        this.tableData = list || []
        this.data = res.data.data || {}
      }
    },
    async getIntClsCount(comName) {
      let res = await getIntClsCount({comName})
      let {code, data} = res.data;
      if (code === 200) {
        this.data = data || {}
      }
    },
    async getAuthInfos() { //获取认证公司名列表
      let userBusinessCode = decodeURI(this.getQueryVariable('company'));
      console.log(userBusinessCode)
      let res = await getAuthInfos({
        userBusinessCode
      })
      if (res.data.code === 200) {
        let comList = []
         comList = res.data.data.map(i => i.businessName);
        if(comList.indexOf(userBusinessCode) == -1){
          comList.push(userBusinessCode)
        }
        this.allcompany = comList
        // console.log(this.allcompany)
        if(this.allcompany.length!=0){
          getWarnlist({comName:this.allcompany}).then(res=>{
            // console.log(res)
            if(res.data.code==200){
              this.warnArr = res.data.data;
              this.warnArr = this.warnArr.filter((item) =>{ return item.doc_count!= 0});
              this.warnArr.map(i => this.warnTotal+= Number(i.doc_count));
            }
          })
        }
        // console.log(this.allcompany)
        return comList || []
      }
    },
    goBrandList(id) {
      if (this.isCompany) {
        this.$router.push(`/intellectualProperty/companyBrandList/${id}?companyName=${this.companyName}`)
      } else {
        // this.$router.push('/intellectualProperty/brandList/' + id)
        this.$router.push(`/intellectualProperty/brandList/${id}?company=${this.bj}`)
      }
    },
    detailHandle(companyName) {
      if (!companyName) return false
      this.$router.push('/intellectualProperty/' + companyName)
    },
	detailHandle2() {
		this.$router.push('/watchGoods/' + this.bj)		
	},
    indexMethod(index) {
      return index + 1;
    },
    tocomfire(){
      this.$router.push({
        path:'/comfire',
        query:{
          company:this.bj
        }
      })
    },
	brandSet(){
      this.$router.push({
        path:'/monitoring',
        query:{
          company:this.bj
        }
      })
    },
    gotomid(){
      this.$router.push({
        path:'/remind',
        query:{
          company:this.bj
        }
      })
    },
    gowarn(val,val2){
      this.$router.push({
        path:'/brandWarn',
         query:{
          warncompany:val,
          total:val2
        }
      })
    },
    // 获取滨江登录的企业
    getQueryVariable(variable) {//获取路径参数
                let query = window.location.search.substring(1);
                let vars = query.split("&");
                for (let i = 0; i < vars.length; i++) {
                    let pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return (false);
            },
        getbj() {
                let brandName = this.getQueryVariable('company')
                if (brandName) {//初始化获取参数执行搜索
                    this.bj = decodeURI(brandName)
                }
                // console.log(this.bj)
                return this.bj
            },
             async init2() {
              if(!this.isCompany){
                let comList = await this.getbj();
               await getfirst({userBusinessCode:comList})
              }
          },
  }
}
</script>
<style>
.main-btn {
  background: #EB5E00;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.showall{
  width: 100px;
height: 35px;
border-radius: 20px;
border: 1px solid #979797;
text-align: center;
margin-left: 935px;
cursor: pointer;
font-weight: 400;
color: #1167D5;
font-size: 14px;
line-height: 35px;
margin-top: 20px;
}
</style>

