<template>
  <el-row v-loading="loading">
      <!-- <el-row type="flex" style="align-items: center;justify-content: flex-end;margin-bottom: 10px;">
        <el-input placeholder="请输入商标名称" size="mini" style="width:200px;margin-right:20px;" v-model="trademarkName"></el-input>
        <el-button size="mini" class="main-btn" @click="queryTrademarkProgressList">搜索</el-button>
      </el-row> -->
    <el-table
      :data="tableData" v-show="tableData.length<7"
      :header-cell-style="{background:'#F8F8F8',color:'#333'}"
    >
      <el-table-column align="center" prop="brandName" label="商标名称"/>
      <el-table-column align="center" prop="intCls" label="类别"/>
      <el-table-column align="center" prop="appDate" label="申请日期"/>
      <el-table-column align="center" prop="TMStatus" label="状态">
        <template slot-scope="scope">
          <div style="color:#1167D5 ;">
            {{scope.row['TMStatus']}}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:30px;">
      <!-- <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        hide-on-single-page
        @current-change="handleCurrentChange"
      >
      </el-pagination> -->
      <div class="showall" @click="showall" v-show="tableData.length!=0">显示全部</div>
    </div>
  </el-row>
</template>

<script>
import {queryTrademarkProgressList} from "../../../api/api";

export default {
  name: "brandApplyProcess",
  data(){
    return {
      loading:false,
      tableData:[],
      total:0,
      pageSize:10,
      pageNum:1,
      trademarkName:''
    }
  },
   props: {
    companyList: {
      type: Array,
      default: () => []
    },
    company:{
      type: String,
    }
  },
  mounted(){
    
    this.queryTrademarkProgressList();
  },
  watch:{
    pageNum(){
      this.queryTrademarkProgressList();
    }
  },
  methods:{
    handleCurrentChange(e){
      this.pageNum = e;
    },
    async queryTrademarkProgressList(){
      console.log(this.companyList)
      this.loading = true;
      let res = await queryTrademarkProgressList({
        tmApplicant:this.companyList
      })
      console.log(res)
      if(res.data.code === 200){
        this.tableData = res.data.data;
      }
      this.loading = false;
    },
    showall(){
      // console.log(this.company)
      this.$router.push({
        path:'applycate',
        query:{
          company:this.company
        }
      })
    }
  }
}
</script>

<style scoped>
.main-btn {
  background: #EB5E00;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.showall{
  width: 100px;
height: 35px;
border-radius: 20px;
border: 1px solid #979797;
text-align: center;
margin-left: 935px;
cursor: pointer;
font-weight: 400;
color: #1167D5;
font-size: 14px;
line-height: 35px;
}
</style>
