<template>
	<el-row v-loading="loading">
		<el-col :span="10">
			<el-table :data="tableData" v-show="tableData.length<7" :header-cell-style="{background:'#F8F8F8',color:'#333'}">
				<el-table-column show-overflow-tooltip prop="pingpai" label="品牌"></el-table-column>
				<el-table-column show-overflow-tooltip prop="shop" label="店铺"></el-table-column>
				<el-table-column show-overflow-tooltip prop="zhandian" label="平台"></el-table-column>
				<el-table-column show-overflow-tooltip prop="xiaoliang" label="销量"></el-table-column>
			</el-table>
		</el-col>
		<el-col :span="10" :offset="4">
			<el-table style="width: 450px;" :data="tableData2" :header-cell-style="{background:'#F8F8F8',color:'#333'}">
				<el-table-column label="图片">
					<template slot-scope="scope">
						<img width="40" height="40" :src="scope.row.pic_url" />
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="goods" label="商品名"></el-table-column>
				<el-table-column show-overflow-tooltip prop="price" label="原价"></el-table-column>
				<el-table-column show-overflow-tooltip prop="nowPrice" label="现价"></el-table-column>
				<el-table-column show-overflow-tooltip prop="change" label="变化率">
					<template slot-scope="scope">
						<div v-if="scope.row.change">{{scope.row.change}}%</div>
					</template>
				</el-table-column>
			</el-table>
		</el-col>

	</el-row>
</template>

<script>
	import { userFirst } from "@/api/monitoring";
	import * as _ from '@/utils/tool'
	export default {
		name: "brandMonitoring",
		data() {
			return {
				loading: false,
				tableData: [],
				tableData2: [],
				page: 1,
				size: 10,
				total: 0,
				sqr: '',
			}
		},
		props: {

		},
		created() {
			this.getList()
		},
		mounted() {

		},
		watch: {

		},
		methods: {
			async getList() {
				let data = {
					"user": this.$store.getters.UserId,
					"dlr": this.$route.query.company,
					"corpId": this.$store.getters.CorpId,
					"sqr": this.sqr
				}
				this.loading = true
				let res = await userFirst(data)
				this.loading = false
				this.tableData = res.data.data
				this.tableData2 = res.data.data1
			},			
			restart() {
				this.page = 1
				this.tableData = new Array()
				this.tableData2 = new Array()
				this.total = 0
			}
		}
	}
</script>

<style scoped>

</style>
